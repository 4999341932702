export default {
  baseUrl: "https://api.unikshoes.com/v1",
  title: "فروشگاه  کفش یونیک",
  colorLoading: "var(--color-themeBlue)",

  firebase: {
    apiKey: "AIzaSyBKLagOM8uWxORAK_1JVykWdHIPcajUGOQ",
    authDomain: "atlasmode-9947b.firebaseapp.com",
    projectId: "atlasmode-9947b",
    storageBucket: "atlasmode-9947b.appspot.com",
    messagingSenderId: "279425365371",
    appId: "1:279425365371:web:3ff0abce89aabd6c91b9de",
    vapidKey:
      "BC6jA4vEEjWPwX06htCNTdNVdleCw4qR6jPwMGAvCFb_rZTQ0jvygsXjkb0JddoeUMXOkgCLma8ZWOa32v64pi8",
  },
};
